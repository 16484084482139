import React from 'react';
import {Link} from 'react-scroll';

export const NavBar = () => {

    return (
        <header className='z-10  items-center lg:justify-start justify-center flex p-3 gap-5 w-screen viewport fixed '>
            <p className='lg:text-[30px] lg:flex hidden pl-3 uppercase font-bold blueGrand '>Jaroslav</p>
            <div className='lg:gap-10 gap-5 flex justify-between  pt-3  lg:text-[17px] lg:font-bold'>
                <Link to='aboutMe' className='hover:text-white blueGrand' activeClass='active' smooth={true} spy={true} >About me</Link>
                <Link to='skills' className='hover:text-white blueGrand' activeClass='active' smooth={true} spy={true} >Skills</Link>
                <Link to='frameworks' className='hover:text-white blueGrand' activeClass='active' smooth={true} spy={true} >Experience</Link>
                <Link to='contact' className='hover:text-white blueGrand' activeClass='active' smooth={true} spy={true} >Contact</Link>
            </div>
                
        </header>
        
            
        
               
    )
}