import React from 'react';
import { FaReact, FaHtml5 } from "react-icons/fa";
import { SiSpring} from "react-icons/si";
import { DiDotnet } from "react-icons/di";



import {motion} from 'framer-motion';
import { fadeIn } from '../variant';

const Frameworks = () => {
    return  (
        
        <section id='frameworks'  className='h-screen items-center flex flex-col mx-auto gap-y-[20px] pl-5 pr-5'>
            <div>
            <motion.div variants={fadeIn('right',0.2)} initial='hidden' whileInView={'show'} 
                        viewport={{once: false, amount: 0.1}} className='flex mx-auto lg:mb-[50px]  lg:p-[0px] p-[18px] mb-[20px]'>
                <h2 className='text-white font-bold lg:text-[60px] text-[40px]'>My </h2><span className='w-2'/>
                <h2 className=' font-bold lg:text-[60px] text-[40px] blueGrand'> Experience </h2>
            </motion.div>
            
                <div className='p-3 flex flex-wrap lg:gap-[100px] gap-9 lg:mb-[50px]'>
                    <motion.div variants={fadeIn('right',0.2)} initial='hidden' whileInView={'show'} 
                    viewport={{once: false, amount: 0.1}} className='lg:p-5 lg:mx-0 w-[310px]  mx-auto items-center  rounded-[20px] lg:w-[335px] '>
                       <div className="flex items-center">
                            <SiSpring className="lg:w-[40px] lg:h-[40px] w-[30px] h-[30px] lg:mr-3 mr-2 animate-gradient" />
                            <p className='lg:mb-2 font-bold lg:text-[40px] text-[25px] blueGrand'>Spring Boot</p>
                        </div>
                        <div className='lg:w-[335px] lg:text-[17px] text-[15px] flex'>
                            <p className='text-white'>Web-App with REST-Api using JUnit</p>
                        </div>
                    </motion.div>

                    <motion.div variants={fadeIn('down',0.2)} initial='hidden' whileInView={'show'} 
                    viewport={{once: false, amount: 0.1}} className='lg:p-5 lg:mx-0 w-[310px] mx-auto  items-center rounded-[20px]  lg:w-[335px] '>
                       <div className="flex items-center">
                            <FaReact className="lg:w-[40px] lg:h-[40px] w-[30px] h-[30px] lg:mr-3 mr-2 animate-gradient" />
                            <p className='lg:mb-2 font-bold lg:text-[40px] text-[25px] blueGrand'>React</p>
                        </div>
                        <div className='lg:w-[335px] lg:text-[17px] text-[15px] flex'>
                            <p className='text-white pb-2'>React Single-Page-Website using Tailwindcss</p>
                           
                        </div>
                    </motion.div>

                    <motion.div variants={fadeIn('left',0.2)} initial='hidden' whileInView={'show'} viewport={{once: false, amount: 0.1}} 
                    className='lg:p-5 pb-7 lg:mx-0 w-[310px] mx-auto items-center rounded-[20px]  lg:w-[335px] '>
                       <div className="flex items-center">
                            <DiDotnet className="lg:w-[40px] lg:h-[40px] w-[30px] h-[30px] lg:mr-3 mr-2 animate-gradient" />
                            <p className='lg:mb-2 font-bold lg:text-[40px] text-[25px] blueGrand'>.Net</p>
                        </div>
                        <div className='lg:w-[335px] lg:text-[17px] text-[15px] flex'>
                            <p className='text-white'>Xamarin, WPF & ASP.Net - Apps using SQL</p>
                        </div>
                    </motion.div>
                    
                    
                    
                </div>
                <div className=' lg:p-3 pr-3 pl-3 flex flex-wrap lg:gap-[130px] gap-9 mb-10'>
                <motion.div variants={fadeIn('right',0.2)} initial='hidden' whileInView={'show'} viewport={{once: false, amount: 0.1}}
                 className='lg:p-5 lg:mx-0 w-[310px] mx-auto items-center lg:ml-[220px] rounded-[20px]  lg:w-[335px] '>
                       <div className="flex items-center">
                            <FaReact className="lg:w-[40px] lg:h-[40px] w-[30px] h-[30px] lg:mr-3 mr-2 animate-gradient" />
                            <p className='lg:mb-2 font-bold lg:text-[40px] text-[25px] blueGrand'>React Native</p>
                        </div>
                        <div className='lg:w-[335px] lg:text-[17px] text-[15px] flex'>
                            <p className='text-white'>Native App using Firebase & Firestore</p>
                        </div>
                    </motion.div>

                    <motion.div variants={fadeIn('left',0.2)} initial='hidden' whileInView={'show'} viewport={{once: false, amount: 0.1}}
                     className='lg:p-5 lg:mx-0 w-[310px] mx-auto items-center  rounded-[20px] lg:w-[335px] '>
                       <div className="flex items-center">
                            <FaHtml5 className="lg:w-[40px] lg:h-[40px] w-[30px] h-[30px] lg:mr-3 mr-2 animate-gradient" />
                            <p className='lg:mb-2  font-bold lg:text-[40px] text-[25px] blueGrand'>HTML/CSS</p>
                        </div>
                        <div className='lg:w-[335px] lg:text-[17px] text-[15px] flex'>
                            <p className='text-white '>HTML using CSS & Bootstrap</p>
                        </div>
                    </motion.div>
   
                </div>
            </div>

        </section>
    )
}

export default Frameworks;