import React from 'react';

import {TypeAnimation} from 'react-type-animation'
import {motion} from 'framer-motion';
import { fadeIn } from '../variant';

const AboutMe = () => {
    return (   
            <section  id='aboutMe' className='h-screen flex pr-[30px]  container mx-auto items-center pl-[30px]'>
                
                    <div className='lg:flex-row  items-center flex flex-col '>
                        <motion.div className='flex-1'>
                            

                            <motion.div variants={fadeIn('down',0.2)} initial='hidden'
                                whileInView={'show'} viewport={{once: false, amount: 0.7}}>
                                <TypeAnimation className='blueGrand font-bold lg:text-[200px] text-[60px]'
                                sequence={['Welcome!', 2000,'Hello!', 2000,'Hi there!', 2000, 'Hey!', 2000,]}
                                repeat={Infinity}  wrapper='span'></TypeAnimation>
                            </motion.div>

                            <motion.h1 variants={fadeIn('down',0.2)} initial='hidden'
                                whileInView={'show'} viewport={{once: false, amount: 0.7}}
                                className=' font-bold text-white lg:text-[50px] text-[30px]'>Jaroslav Romashchuk</motion.h1>

                            <motion.p className='mb-4 text-white lg:max-w-[513px] text-[16px] ' variants={fadeIn('down',0.4)} initial='hidden'
                                whileInView={'show'} viewport={{once: false, amount: 0.7}}>
                                    I'm a software engineer from Vienna and I'm ready to dive into new opportunities and enhance my skills and knowledge.
                            </motion.p>
                            <motion.div className='pt-3 ' variants={fadeIn('up',0.4)} initial='hidden'
                                whileInView={'show'} viewport={{once: false, amount: 0.7}}>
                            <motion.a  className='rounded-[10px] mt-[62px] text-white font-bold  pt-2 pb-2 pr-5 pl-5
                            blueBackGroundGrandLtoR' href="https://www.linkedin.com/in/jaroslav-romashchuk/">Contact me</motion.a>
                           </motion.div>
                        </motion.div>
                        
                        

                        
                    </div>
                    
            </section>
        );
}

export default AboutMe;