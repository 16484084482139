import React from 'react';


const Footer = () => {
    return  (
        <div className='mt-12 text-gray-500 text-center ' id='footer'>
                <p className='pt-5 pb-5'> Personal Portfolio <br/> Jaroslav Romashchuk <br/> Vienna, Austria </p>

        </div>
    )
}

export default Footer;