import React from 'react';
import {motion} from 'framer-motion';
import { fadeIn,fillProgress } from '../variant';


const Skills = () => {
    return (
        <section  id='skills' className='flex-col h-screen  items-center mx-auto pl-5 pr-5'>
        <div className=' p-10'>
          <motion.div variants={fadeIn('down',0.2)} initial='hidden' whileInView={'show'} 
            viewport={{once: false, amount: 0.1}}>
            <div className='flex pb-10'>
                <h2 className='text-white font-bold lg:text-[60px] text-[40px]'>My </h2><span className='w-2'/>
                <h2 className=' font-bold lg:text-[60px] blueGrand text-[40px]'> Skills </h2>
            </div>
            </motion.div>  
            <div className='lg:flex md:flex gap-x-[120px]'>
                <div className=''>
                    <div className='pb-5'>
                    <div className='flex items-center'>
                        <motion.p variants={fadeIn('right',0.2)} initial='hidden' whileInView={'show'} 
                        viewport={{once: false, amount: 0.1}} className='text-white font-bold p-1 lg:text-[30px] text-[25px]'>Java</motion.p>
                        <motion.p variants={fadeIn('right',0.2)} initial='hidden' whileInView={'show'} 
                        viewport={{once: false, amount: 0.1}}  className='text-white font-bold p-1 lg:text-[15px] text-[15px]'>(Spring, Hibernate)</motion.p>
                    </div>
                        <motion.div variants={fadeIn('right',0.2)} initial='hidden' whileInView={'show'} 
                        viewport={{once: false, amount: 0.1}} className="lg:w-[555px] lg:h-7 w-[310px] h-4 bg-gray-200">  
                            <motion.div
                                className="h-full blueBackGroundGrandLtoR"
                                variants={fillProgress(74,0.5)}
                            ></motion.div>
                        </motion.div>
                    </div>
                    <div className='pb-5'>
                    <div className='flex items-center'>
                        <motion.p variants={fadeIn('right',0.2)} initial='hidden' whileInView={'show'} 
                        viewport={{once: false, amount: 0.1}}  className='text-white font-bold p-1 lg:text-[30px] text-[25px]'>JavaScript</motion.p>
                        <motion.p variants={fadeIn('right',0.2)} initial='hidden' whileInView={'show'} 
                        viewport={{once: false, amount: 0.1}}  className='text-white font-bold p-1 lg:text-[15px] text-[15px]'>(React)</motion.p>
                    </div>
                        <motion.div variants={fadeIn('right',0.2)} initial='hidden' whileInView={'show'} 
                        viewport={{once: false, amount: 0.1}} className="lg:w-[555px] lg:h-7 w-[310px] h-4 bg-gray-200">  
                            <motion.div
                                className="h-full blueBackGroundGrandLtoR"
                                variants={fillProgress(55,0.5)}
                            ></motion.div>
                        </motion.div>
                    </div>
                    <div className='pb-5'>
                    <div className='flex items-center'>
                        <motion.p variants={fadeIn('right',0.2)} initial='hidden' whileInView={'show'} 
                        viewport={{once: false, amount: 0.1}}  className='text-white font-bold p-1 lg:text-[30px] text-[25px]'>Database</motion.p>
                        <motion.p variants={fadeIn('right',0.2)} initial='hidden' whileInView={'show'} 
                        viewport={{once: false, amount: 0.1}}  className='text-white font-bold p-1 lg:text-[15px] text-[15px]'>(MySQL, PostgreSQL)</motion.p>
                    </div>
                        <motion.div variants={fadeIn('right',0.2)} initial='hidden' whileInView={'show'} 
                        viewport={{once: false, amount: 0.1}} className="lg:w-[555px] lg:h-7 w-[310px] h-4 bg-gray-200">  
                            <motion.div
                                className="h-full blueBackGroundGrandLtoR"
                                variants={fillProgress(60,0.5)}
                            ></motion.div>
                        </motion.div>
                    </div>
                    
                    
                </div>
                <div className=''>
                    <div className='pb-5'>
                    <div className='flex items-center'>
                        <motion.p variants={fadeIn('left',0.2)} initial='hidden' whileInView={'show'} 
                        viewport={{once: false, amount: 0.1}}  className='text-white font-bold p-1 lg:text-[30px] text-[25px]'>C#</motion.p>
                        <motion.p variants={fadeIn('left',0.2)} initial='hidden' whileInView={'show'} 
                        viewport={{once: false, amount: 0.1}}  className='text-white font-bold p-1 lg:text-[15px] text-[15px]'>(ASP.NET, XAMARIN, WPF)</motion.p>
                    </div>
                        <motion.div variants={fadeIn('left',0.2)} initial='hidden' whileInView={'show'} 
                        viewport={{once: false, amount: 0.1}} className="lg:w-[555px] lg:h-7 w-[310px] h-4 bg-gray-200">  
                            <motion.div
                                className="h-full blueBackGroundGrandLtoR"
                                variants={fillProgress(74,0.5)}
                            ></motion.div>
                        </motion.div>
                    </div>
                    <div className='pb-5'>
                        <motion.p variants={fadeIn('left',0.2)} initial='hidden' whileInView={'show'} 
                        viewport={{once: false, amount: 0.1}}  className='text-white font-bold p-1 lg:text-[30px] text-[25px]'>HTML/CSS</motion.p>
                       
                        <motion.div variants={fadeIn('left',0.2)} initial='hidden' whileInView={'show'} 
                        viewport={{once: false, amount: 0.1}} className="lg:w-[555px] lg:h-7 w-[310px] h-4 bg-gray-200">  
                            <motion.div
                                className="h-full blueBackGroundGrandLtoR"
                                variants={fillProgress(62,0.5)}
                            ></motion.div>
                        </motion.div>
                    </div>
                    <div className='pb-5'>
                        <motion.p variants={fadeIn('left',0.2)} initial='hidden' whileInView={'show'} 
                        viewport={{once: false, amount: 0.1}} className='text-white font-bold p-1 lg:text-[30px] text-[25px]'>GIT</motion.p>

                        <motion.div variants={fadeIn('left',0.2)} initial='hidden' whileInView={'show'} 
                        viewport={{once: false, amount: 0.1}} className="lg:w-[555px] lg:h-7 w-[310px] h-4 bg-gray-200">  
                            <motion.div
                                className="h-full blueBackGroundGrandLtoR"
                                variants={fillProgress(40,0.5)}
                            ></motion.div>
                        </motion.div>
                    </div>
                    
                </div>
            </div>
                    
            </div>
        </section>
    )
}

export default Skills;