import './App.css';
import React from 'react';

import { NavBar } from './components/NavBar';
import AboutMe from './components/AboutMe'
import Frameworks from './components/Frameworks'
import Skills from './components/Skills'
import Contact from './components/Contact'
import Footer from './components/Footer'



function App() {
  return (
    <div className="bg-neutral-900 overflow-x-hidden">
    
    <NavBar/>
    <AboutMe></AboutMe>
    <Skills></Skills>
    <Frameworks></Frameworks>
    <Contact></Contact>
    <Footer></Footer>

  </div>
  );
}

export default App;
